<template>
  <div class="contact">
    <span class="contact__address">
      Praxis Badenerstrasse 21<br />
      <a
        href="https://www.google.com/maps/place/Badenerstrasse+21,+8004+Z%C3%BCrich/@47.3732023,8.5296949,19.75z/data=!4m5!3m4!1s0x47900a04b2d4bdfb:0xdefe8a76e15ebada!8m2!3d47.3733075!4d8.5298261"
        target="blank"
      >
        Badenerstrasse 21, 8004 Zürich</a
      ><br /><br />
    </span>
    +41 79 614 26 18<br />
    <a href="mailto:kontakt@tinabeerli.ch" target="_blank"
      >kontakt@tinabeerli.ch</a
    >
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {};
  },
  mounted() {},
  methods: {}
});
</script>
